'use client';

import React, { Suspense, useCallback, useEffect, useMemo, useRef, lazy } from 'react';
import { useForm } from 'react-hook-form';
import debounce from 'lodash-es/debounce';
import { useRelatedProducts } from '@/hooks/use-related-products';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import useSearchState from '@/stores/search-state';
import { useStoreData } from '@/hooks/use-store-data';
import analytics from '@/lib/analytics';
import Overlay from '@/components/Overlay';
import SearchWithProducts from '@/components/SearchWithProducts';
import CarouselSkeleton from '@/components/CarouselSkeleton';

import styles from './index.module.css';

const Carousel = lazy(() => import('@/components/Carousel'));

const SearchComponent: React.FC = () => {
  const { register, watch, setValue } = useForm();
  const searchValue = watch('SearchBar');
  const { storeData } = useStoreData();

  const {
    searchQuery,
    setSearchQuery,
    showSearch,
    setShowSearch,
    searchResults,
    loading: searchLoading,
  } = useSearchState((state) => state);
  const { products: latestProducts, loading: latestLoading } = useRelatedProducts([], 'different-listing');

  // track search event emitter (in a ref for stability)
  const currentFireSearchEvent = useRef<(searchTerm: string) => void>(() => {});
  useEffect(() => {
    currentFireSearchEvent.current = (searchTerm: string) => {
      if (searchTerm && searchTerm.trim() !== '') {
        analytics.track('search_event', { searchTerm }, storeData);
      }
    };
  }, [storeData]);
  // track the search event after 2 seconds of inactivity (in a ref for stability)
  const debouncedFireSearchEvent = useRef(
    debounce((searchTerm: string) => currentFireSearchEvent.current(searchTerm), 2000)
  );
  // flush search event when unloading
  useEffect(() => () => debouncedFireSearchEvent.current.flush(), []);

  // track setSearchQuery (in a ref for stability)
  const currentSetSearchQuery = useRef<(searchTerm: string) => void>(() => setSearchQuery);
  useEffect(() => {
    currentSetSearchQuery.current = setSearchQuery;
  }, [setSearchQuery]);
  // create a stable debounced setSearchQuery() call that will invoke the current setSearchQuery()
  const debouncedSetSearchQuery = useRef(
    debounce((searchTerm: string) => currentSetSearchQuery.current(searchTerm), 300)
  );

  // update the searchQuery consumers
  useEffect(() => {
    const searchTerm = (typeof searchValue === 'string' && searchValue) || '';

    // kick off deferred search term actions (set the search and fire metrics)
    debouncedSetSearchQuery.current(searchTerm);
    debouncedFireSearchEvent.current(searchTerm);

    const cleanupTarget = debouncedSetSearchQuery.current;
    return () => {
      // cancel the debounced call if we're on the way out (or about to set a value)
      cleanupTarget.cancel();
    };
  }, [searchValue]);

  const closeSearch = useCallback(() => {
    debouncedFireSearchEvent.current.flush();
    setValue('SearchBar', '');
    setSearchQuery('');
    setShowSearch(false);
  }, [setValue, setSearchQuery, setShowSearch]);

  const onProductClicked = useCallback(() => {
    debouncedFireSearchEvent.current.flush();
    setShowSearch(false);
  }, [setShowSearch]);

  const currentLatestProducts = useMemo(() => {
    return latestProducts.slice(0, 8);
  }, [latestProducts]);

  let content;

  if (!searchValue) {
    content = (
      <Suspense fallback={<CarouselSkeleton />}>
        <Carousel
          name='Latest Products'
          productData={currentLatestProducts}
          loading={latestLoading}
          onProductClicked={onProductClicked}
          nextButtonId='nextSearch'
          prevButtonId='prevSearch'
          placement='search'
        />
      </Suspense>
    );
  } else if (searchResults.length > 0) {
    content = (
      <SearchWithProducts searchValue={searchQuery} searchResults={searchResults} onProductClicked={onProductClicked} />
    );
  } else {
    content = (
      <>
        <div className={styles.headerContainer}>
          <h3>{`Sorry, no results for "${searchValue}". Please try a different keyword.`}</h3>
        </div>
        <Suspense fallback={<CarouselSkeleton />}>
          <Carousel
            name='You might also like:'
            productData={currentLatestProducts}
            loading={searchLoading}
            onProductClicked={onProductClicked}
            nextButtonId='nextSearch'
            prevButtonId='prevSearch'
            placement='search'
          />
        </Suspense>
      </>
    );
  }

  return (
    <>
      <button type='button' onClick={() => setShowSearch(true)} className={styles.searchButton}>
        <span className='sr-only'>Search</span>
        <MagnifyingGlassIcon className={styles.iconStyle} aria-hidden='true' />
      </button>
      {showSearch && (
        <Overlay show={showSearch} onClose={closeSearch}>
          <div className={styles.overlayContent}>
            <div className={styles.inputRow}>
              <MagnifyingGlassIcon className={styles.iconStyle} aria-hidden='true' />
              <input
                type='text'
                {...register('SearchBar')}
                className={`${styles.searchInput} border-none outline-none ring-0 focus:border-none focus:ring-0 focus:outline-none active:border-none active:outline-none`}
                placeholder='Search everything'
              />
              <button type='button' onClick={closeSearch}>
                <span className='sr-only'>Close</span>
                <XMarkIcon className={styles.iconStyle} aria-hidden='true' />
              </button>
            </div>
            <hr className={styles.horizontalRule} />
            <div className={styles.resultContainer}>{content}</div>
          </div>
        </Overlay>
      )}
    </>
  );
};

export default SearchComponent;
