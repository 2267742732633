import React from 'react';
import Link from 'next/link';
import parse from 'html-react-parser';
import SocialMediaComponent from '@/components/SocialMediaComponent';
import { useStoreTheme } from '@/hooks/use-store-theme';
import { useStoreData } from '@/hooks/use-store-data';
import analytics from '@/lib/analytics';
import StoreLogo from '@/components/StoreLogo';
import RegionAndCurrencyComponent from '@/components/RegionAndCurrencyComponent';
import SpringByAmaze from '@/components/Icons/SpringByAmazeLogo';

import styles from './index.module.css';

const footerNavigation = {
  showNewsletter: false,
  support: [
    {
      name: 'Support',
      href: 'https://sprisupport.zendesk.com/hc/en-us/requests/new',
    },
    {
      name: 'Track Order',
      href: 'https://teespring.com/track/?utm_source=store&utm_medium=footer&utm_campaign=track_order_link',
    },
    {
      name: 'Contact Us',
      href: 'https://sprisupport.zendesk.com/hc/en-us/requests/new',
    },
    {
      name: 'Refund Policy',
      href: 'https://sprisupport.zendesk.com/hc/en-us/articles/12171053036685-Returns-And-Cancellations',
    },
  ],
};

export type FooterOptions = {
  showSocialIcons?: boolean;
};

// optional FooterOptions to version with all members required (defaults applied)
function expandFooterOptions(options?: FooterOptions): Required<FooterOptions> {
  const { showSocialIcons = true } = options || {};
  return {
    showSocialIcons,
  };
}

export type Props = {
  options?: FooterOptions;
};

const Footer: React.FC<Props> = ({ options: rawOptions }) => {
  const { storeData } = useStoreData();
  const storeTheme = useStoreTheme();
  const options = expandFooterOptions(rawOptions);

  const footerStyles = storeTheme?.styles?.footer;
  const footerBackgroundColor = footerStyles?.bgStyles?.backgroundColor || '';
  const textColor = footerStyles?.textStyles?.color || '';

  const footerAboutText = storeTheme?.content?.footer?.about?.textContent || '';
  const showPoweredBy = !storeTheme?.content?.footer?.hidePoweredBy;

  const analyticsTrack = (e: any) => {
    const link = e.target.text;
    analytics.track('footer_link', { link }, storeData);
  };

  return (
    <footer
      aria-labelledby='footer-heading'
      style={{ backgroundColor: footerBackgroundColor || 'black', color: textColor }}
      className={styles.footerBackground}
    >
      <h2 id='footer-heading' className={styles.footerHeading}>
        Footer
      </h2>
      <div className={styles.footerContainer}>
        <div className={styles.footerInnerContainer}>
          <StoreLogo storLogoStyle={styles.footerLogo} />
          {footerAboutText.length > 0 && <p className={styles.aboutText}>{parse(footerAboutText)}</p>}
          <nav className={styles.footerNav} aria-label='Footer'>
            {footerNavigation?.support?.map((item) => (
              <div key={item.name} className={styles.footerItem}>
                <Link href={item.href} className={styles.footerLink} onClick={analyticsTrack} target='_blank'>
                  {item.name}
                </Link>
              </div>
            ))}
          </nav>
          {options.showSocialIcons && (
            <SocialMediaComponent
              linkContainerClass={styles.socialMediaContainer}
              linkClassName={styles.socialMediaLink}
              iconClassName={styles.socialMediaIcon}
            />
          )}
          {showPoweredBy && (
            <p className={styles.poweredByText}>
              <Link
                href='https://www.spri.ng/'
                target='_blank'
                rel='noopener noreferrer'
                className={styles.poweredByLink}
                onClick={analyticsTrack}
              >
                Powered by
                <span className={styles.springLogoContainer}>
                  <SpringByAmaze />
                </span>
              </Link>
            </p>
          )}
        </div>
        <div className={styles.footerBottomContainer}>
          <div className={styles.footerBottomItem}>
            <div className='fade-in'>
              <div className={styles.regionCurrency}>
                <RegionAndCurrencyComponent />
              </div>
            </div>
          </div>
          <ul className={styles.footerList}>
            <li className={styles.footerListItem}>
              <Link rel='noreferrer' href='/privacy-policy' onClick={analyticsTrack} target='_blank'>
                Privacy Policy
              </Link>
            </li>
            <li className={styles.footerListItem}>
              <Link rel='noreferrer' href='/terms-of-use' onClick={analyticsTrack} target='_blank'>
                Terms of use
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
